@tailwind base;
@tailwind components;
@tailwind utilities;

.anonymous {
	color: #75c1d5;
	text-decoration: underline;
	font-weight: 800;
}

.decoration-none {
	text-decoration: none;
}

/* 300 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-Light.ttf");
	font-weight: 300;
}

/* 400 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-Regular.ttf");
}

/* 500 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-Medium.ttf");
	font-weight: 500;
}

/* 600 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-SemiBold.ttf");
	font-weight: 600;
}

/* 700 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-Bold.ttf");
	font-weight: bold;
}

/* 800 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-ExtraBold.ttf");
	font-weight: 800;
}

/* 900 */
@font-face {
	font-family: "Poppins";
	src: url("Fonts/Poppins/Poppins-Black.ttf");
	font-weight: 900;
}

* {
	font-family: "Poppins", "Inter", sans-serif;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-25px);
		-ms-transform: translateY(-25px);
		transform: translateY(-25px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	animation-duration: 1s;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	animation-duration: 1s;
}
