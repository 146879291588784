.carousel.carousel-slider {
	overflow: visible;
}

.carousel.carousel-slider .control-dots {
	transform: translateY(48px);
}

.testimony .slide .img {
	-webkit-animation: testim-hide 0.5s ease-in-out forwards;
	-moz-animation: testim-hide 0.5s ease-in-out forwards;
	-ms-animation: testim-hide 0.5s ease-in-out forwards;
	-o-animation: testim-hide 0.5s ease-in-out forwards;
	animation: testim-hide 0.5s ease-in-out forwards;
}

.testimony .slide .title {
	-webkit-animation: testim-content-out 0.4s ease-in-out forwards;
	-moz-animation: testim-content-out 0.4s ease-in-out forwards;
	-ms-animation: testim-content-out 0.4s ease-in-out forwards;
	-o-animation: testim-content-out 0.4s ease-in-out forwards;
	animation: testim-content-out 0.4s ease-in-out forwards;
}

.testimony .slide .subtitle {
	-webkit-animation: testim-content-out 0.5s ease-in-out forwards;
	-moz-animation: testim-content-out 0.5s ease-in-out forwards;
	-ms-animation: testim-content-out 0.5s ease-in-out forwards;
	-o-animation: testim-content-out 0.5s ease-in-out forwards;
	animation: testim-content-out 0.5s ease-in-out forwards;
}

.testimony .slide.selected .img {
	-webkit-animation: testim-show 0.5s ease-in-out forwards;
	-moz-animation: testim-show 0.5s ease-in-out forwards;
	-ms-animation: testim-show 0.5s ease-in-out forwards;
	-o-animation: testim-show 0.5s ease-in-out forwards;
	animation: testim-show 0.5s ease-in-out forwards;
}

.testimony .slide.selected .title {
	-webkit-animation: testim-content-in 0.4s ease-in-out forwards;
	-moz-animation: testim-content-in 0.4s ease-in-out forwards;
	-ms-animation: testim-content-in 0.4s ease-in-out forwards;
	-o-animation: testim-content-in 0.4s ease-in-out forwards;
	animation: testim-content-in 0.4s ease-in-out forwards;
}

.testimony .slide.selected .subtitle {
	-webkit-animation: testim-content-in 0.5s ease-in-out forwards;
	-moz-animation: testim-content-in 0.5s ease-in-out forwards;
	-ms-animation: testim-content-in 0.5s ease-in-out forwards;
	-o-animation: testim-content-in 0.5s ease-in-out forwards;
	animation: testim-content-in 0.5s ease-in-out forwards;
}

@keyframes testim-content-in {
	from {
		opacity: 0;
		transform: translateY(100%);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes testim-content-out {
	from {
		opacity: 1;
		transform: translateY(0);
	}

	to {
		opacity: 0;
		transform: translateY(-100%);
	}
}

@keyframes testim-hide {
	from {
		opacity: 1;
		transform: scale(1);
	}

	to {
		opacity: 0;
		transform: scale(0);
	}
}

@keyframes testim-show {
	from {
		opacity: 0;
		transform: scale(0);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}
